import React from "react"
import styled from "styled-components"
import "./style.scss"
import ImgPackagesBasic from "../img/packages-basic"
import ImgPackagesEnterprise from "../img/packages-enterprise"
import IconStar from "../icons/icon-star"
import IconPresentation from "../icons/icon-presentation"
import IconDNA from "../icons/icon-dna"
import IconBook from "../icons/icon-book"
import IconShopping from "../icons/icon-shopping"
import IconUsers from "../icons/icon-users"
import IconDatabase from "../icons/icon-database"
import CTAButton from "../components/cta-button"

const Component = styled.div`
    text-align: center;
    h2, h3 {
        padding-top: 0.25em;
        max-width: 100%;
    }
    ul {
        list-style: none;
        padding-left: 0;
        text-align: left;
        margin: 3em 0;
    }
    li {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 1em;
    }
    svg {
        min-width: 2em;
        margin-right: 1em;
    }
    p {
        margin: 0;
    }
    .packages-card {
        padding: 3em 5%;
        margin: 3em 0;
        @media screen and (min-width: 921px) {
            padding: 4em 3em;
        }
    }
    .packages-image {
        width: 96px;
        margin: 0 auto 1em auto;
    }
    #packages-cta {
        padding: 3em 10%;
        width: 90%;
        max-width: 830px;
        margin: auto;
        text-align: left;
        @media screen and (min-width: 691px) {
            width: 67%;
            padding: 3em;
        }
    }
`

const DesignPackages = () => (
    <Component>
        <div id="packages-section" class="section">
            <div class="cols-1 text-invert">
                <h1>Web Design Packages</h1>
                <p>My web design packages are highly scalable, capable of serving small start-ups and established companies alike.</p>
            </div>
            <div class="cols-1-1">
                <div class="packages-card">
                    <div class="packages-image">
                        <ImgPackagesBasic/>
                    </div>
                    <h2>Essentials</h2>
                    <ul class="has-inline-svg">
                        <li><IconStar/><p><strong>Fully-customised website</strong> tailored to your brand, goals, and customers.</p></li>
                        <li><IconPresentation/><p><strong>Strategy workshop</strong> to plan for the best web experience.</p></li>
                        <li><IconDNA/><p><strong>Brand fonts and colours</strong> to build a consistent visual branding identity.</p></li>
                        <li><IconBook/><p><strong>Website manual</strong> so you’ll always know how your website works.</p></li>
                        <li class="packages-list-disabled"><IconShopping/><p><strong>eCommerce capability</strong> to generate online sales and manage inventory.</p></li>
                        <li class="packages-list-disabled"><IconUsers/><p><strong>Personalized training</strong> to manage and update your website independently.</p></li>
                        <li class="packages-list-disabled"><IconDatabase/><p><strong>Complete infrastructure access</strong> for full control and rights over your website.</p></li>
                    </ul>
                    <h3 class="h3-on-h1">From</h3>
                    <h2>MYR 5,490</h2>
                </div>
                <div class="packages-card">
                    <div class="packages-image">
                        <ImgPackagesEnterprise/>
                    </div>
                    <h2>Professional</h2>
                    <ul class="has-inline-svg">
                        <li><IconStar/><p><strong>Fully-customised website</strong> tailored to your brand, goals, and customers.</p></li>
                        <li><IconPresentation/><p><strong>Strategy workshop</strong> to plan for the best web experience.</p></li>
                        <li><IconDNA/><p><strong>Brand fonts and colours</strong> to build a consistent visual branding identity.</p></li>
                        <li><IconBook/><p><strong>Website manual</strong> so you’ll always know how your website works.</p></li>
                        <li class="packages-list-extra"><IconShopping/><p><strong>eCommerce capability</strong> to generate online sales and manage inventory.</p></li>
                        <li class="packages-list-extra"><IconUsers/><p><strong>Personalized training</strong> to manage and update your website independently.</p></li>
                        <li class="packages-list-extra"><IconDatabase/><p><strong>Complete infrastructure access</strong> for full control and rights over your website.</p></li>
                    </ul>
                    <h3 class="h3-on-h1">From</h3>
                    <h2>MYR 9,490</h2>
                </div>
            </div>
            <div className="cols-1">
                <h3>Giving back to the community</h3>
                <p>Are you a <strong>non-profit organization</strong> or <strong>charity</strong> body?<br/>Let’s arrange for a special alternative package.</p>
            </div>
        </div>
        <div className="section-highlight-top section">
            <div id="packages-cta" className="text-invert cta">
                <h1>How do we start?</h1>
                <p>Let’s get to know each other. We’ll talk about your business and your website to see if we make a good fit.</p>
                <CTAButton url="/contact">Contact me</CTAButton>
            </div>
        </div>
    </Component>
)

export default DesignPackages
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CTAButton from "../components/cta-button"
import DesignPackages from "../components/design-packages"
import ImgPackagesWrite from "../img/packages-write"
import ImgPackagesCheers from "../img/packages-cheers"
import ImgPackagesAbout from "../img/packages-about"
import IconCheck from "../icons/icon-check"
import Awards from "../components/awards"

const PackagesPage = () => (
    <Layout>
        <SEO title="Packages" description="Discover comprehensive packages; all you need to create a world-class custom website."/>
        <div className="cols-1-1">
            <div>
                <h3 class="h3-on-h1">Packages</h3>
                <h1>A high-quality website is a carefully crafted product.</h1>
                <p>High-value customers expects high-quality websites. Creating one involves extensive research, planning, prototyping, development, testing, optimization, and continuous improvement.</p>
                <p>This demands a lot of skill, experience, and hard work to produce on behalf of the web designer. But there’s one factor that frequently gets ignored in many web projects.</p>
            </div>
        </div>
        <div className="cols-2-1 section">
            <div>
                <ImgPackagesWrite/>
            </div>
        </div>
        <div class="section">
            <div className="cols-1-1">
                <div></div>
                <div>
                    <h1>How I’m different.</h1>
                    <p>Many web designers copy and paste brochure texts into generic website templates and call it a day. This neglects your vision, your expertise, and your customers in the creative process.</p>
                    <p>I’m good at designing and coding websites. And <strong>no one knows your business and customers better than you do.</strong></p>
                    <p>That’s why I place high importance on putting our strengths together. <strong>Effective collaboration</strong> is key to creating a successful, world-class website.</p>
                </div>
            </div>
        </div>
        <div class="section section-highlight">
            <div className="cols-1-1">
                <div>
                    <h1>Our collaboration plan.</h1>
                </div>
            </div>
            <ul className="cols-1-1-1">
                <li>
                    <h3>Strategy workshop</h3>
                    <p>We’ll do a kick-off meeting and brainstorm session to plan how your website can best serve your customer’s needs and exceed their expectations.</p>
                </li>
                <li>
                    <h3>Development</h3>
                    <p>Once you’re happy with the plan, I’ll start to build your website. I’ll check in with you often to make sure we are aligned. After final quality checks, your website will be delivered to you.</p>
                </li>
                <li>
                    <h3>Launch and improve</h3>
                    <p>Launch and promote your new website to the world! I’ll stick around to manage, update, and make sure things run well. We’ll continue to work on improvements based on real data.</p>
                </li>
            </ul>
        </div>
        <div class="section-highlight-top">
            <div className="cols-2-1">
                <ImgPackagesCheers/>
            </div>
        </div>
        <div className="cols-1-1 section">
            <div>
                <h1>We would make an awesome team if you...</h1>
                <ul class="has-inline-svg">
                    <li><IconCheck/>Have a long term vision for your business.</li>
                    <li><IconCheck/>Will go above and beyond to provide amazing customer experience.</li>
                    <li><IconCheck/>Dream of a world-class website to represent your brand.</li>
                    <li><IconCheck/>Need a competent web designer to make it a reality.</li>
                </ul>
            </div>
        </div>
        <DesignPackages/>
        <Awards/>
        <div className="cols-1-1">
            <div className="section">
                <h1>You’re in good hands.</h1>
                <p>Hi again. My name is Nadzim. I created my first website back in 2000. Throughout 23 years, I have learnt a lot what makes a website great and what doesn’t.</p>
                <p>Learn more about my web development journey and why I started Nadzim.com to <strong>help talented entrepreneurs like you.</strong></p>
                <CTAButton url="/about">Get to know me</CTAButton>
            </div>
            <div className="section">
                <ImgPackagesAbout/>
            </div>
        </div>
    </Layout>
)

export default PackagesPage